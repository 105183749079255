import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteName: name
        siteTitle: title
        siteDesc: description
        siteUrl
        image
        organization {
          name
          url
          logo
        }
        social {
          twitter
        }
      }
    }
  }
`

const SEO = ({ title, description, url, isArticle }) => {
  const { site } = useStaticQuery(getData)
  const {
    siteName,
    siteDesc,
    siteTitle,
    siteUrl,
    image,
    social: { twitter },
  } = site.siteMetadata

  const finalTitle = title ? `${title} · ${siteName}` : siteTitle
  const finalDesc = description || siteDesc
  const finalUrl = url || siteUrl

  return (
    <React.Fragment>
      <Helmet title={finalTitle} htmlAttributes={{ lang: "en" }}>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        {/* General tags */}
        <meta name="description" content={finalDesc} />
        <meta name="image" content={image} />
        <link rel="canonical" href={finalUrl} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={finalUrl} />
        {isArticle ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={finalTitle} />
        <meta property="og:description" content={finalDesc} />
        <meta property="og:image" content={image} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={finalTitle} />
        <meta name="twitter:description" content={finalDesc} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </React.Fragment>
  )
}

SEO.propTypes = {
  isArticle: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
}

SEO.defaultProps = {
  isArticle: false,
  title: null,
  description: null,
  url: null,
}

export default SEO
